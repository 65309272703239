import safeRegex from 'safe-regex2';

import {CreditCard} from '../credit-cards/credit-card.class';
import {OrderHeader} from './order-header.class';
import {User} from '../users/user.class';
import {
    COMPACTVIEWTHRESHOLD,
    ImpAddress,
    ImpRep,
    ImpSubtotal,
    OrderCharge,
    OrderLine,
    OrderViewType,
    PCodeType,
    validOrderRegexString,
} from './order.types';

export class Order {
    ['login-name']: string;
    account: string;
    accountCode: string;
    allLines: OrderLine[];
    allowanceApplied?: boolean;
    allowanceDesc?: string;
    allowancePcode?: string;
    amount: number;
    amountSetup: boolean;
    approvalAmt: number;
    aprxFrtChrg?: number;
    aprxFrtTot: number;
    aprxTax: number;
    ariba: boolean;
    aribaDt?: string;
    badge: string;
    billTo: ImpAddress;
    billToLock: boolean;
    caDropshipMsg?: boolean;
    cannotUseCreditCard: boolean;
    canSplitPmt?: boolean;
    cc: CreditCard[];
    charges: OrderCharge[];
    cid: number;
    costCenter: string;
    currency?: string;
    currRate: number;
    custPoRequired: boolean;
    custPoRequiredAmt: number;
    dcOrder: string;
    dcs: null;
    defaultShipVia: string;
    emailOrder: boolean;
    errMsg: string;
    error?: string;
    errorsArray: string[];
    estimatedWeight: string;
    estSplitBalance?: number;
    faxOrder: boolean;
    forceTax: boolean;
    freightAccount: string;
    frtMsg?: string;
    frtPaidAmt?: number;
    frtPending: boolean;
    giftDesc?: string;
    giftEmail?: string;
    hasAllowance?: boolean;
    hasBackorder: boolean;
    hasMinOrdAmt?: boolean;
    hazardousFeeAmount: number;
    isFedex: boolean;
    location: string;
    managerComment: string;
    managerStatus: boolean;
    messages: string[];
    minOrdAmt?: number;
    mobileOrder: string;
    mustProvidePO: boolean;
    mustUseCreditCard?: boolean;
    orderCC?: CreditCard; // CC used for order
    orderLines: number;
    orderNbr: string;
    orderReadOnly: boolean;
    orderTotal: number;
    ordProc: string;
    ordrDt: string;
    ordrLines: number;
    ordrNbr: string;
    ordrNeedsApprv: boolean;
    payterm: string;
    pcode: string;
    pcodeOptions?: 'single' | 'multi';
    pcodeType?: PCodeType;
    po: string;
    poHelp?: string;
    poRegex?: string;
    project: string;
    punchIn: boolean;
    quoteComment?: string;
    quoteExpDt: string;
    recStatus: number;
    remainingBal?: number;
    rep: ImpRep;
    rma: boolean;
    scanErrors: string[];
    scanner: boolean;
    shipMeter: boolean;
    shipTo: ImpAddress;
    shipToLock: boolean;
    shipVia: string;
    shipViaDesc: string;
    shopSetup: boolean;
    someNonManual: boolean;
    status: `Expired`;
    statusDt: string;
    subtotals?: ImpSubtotal[];
    surcharge?: boolean;
    taxCode: `E` | `S` | `T`;
    taxPercent: number;
    type: `` | `Q`;
    userApprvNeeded: boolean;
    validShipVias: string;
    warningsArray: string[];
    webOrder: boolean;

    constructor(orderData) {
        // Assign props from orderData
        Object.assign(this, orderData);

        // Transform data
        this._changeAprxFrtTotToNum();
    }

    /**
     * Returns true if an order can be deleted
     * @param order
     * @param user
     */
    public static canDelete(order: Order, user: User): boolean {
        // Orders in managerStatus cannot be deleted
        if (order.managerStatus) {
            return false;
        }

        // Quotes can only be deleted by the creator
        return !(order.type === `Q` && order[`login-name`] !== user.loginName);
    }

    /**
     * Determines if user can proceed from order detail page
     */
    public canProceed(user: User, isPunchin: boolean): boolean {
        // Order must have items on it
        if (this.orderLines === 0) {
            return false;
        }

        // Punchout or punchin users cannot proceed with errors
        if ((user.isPunchout || isPunchin) && this.errorsArray && this.errorsArray.length) {
            return false;
        }

        // O/W users cannot proceed with a punchin order
        if (isPunchin && user.isImpersonating()) {
            return false;
        }

        // Default is true
        return true;
    }

    /**
     * Used to determine the orderDetailView type based on preference
     * Default view is 'standard'. Cookie takes priority.
     * @param orderLines - Number of lines on the order
     * @param orderDetailViewCookie - User's orderDetailView cookie value
     */
    public static determineOrderViewType(orderLines: number, orderDetailViewCookie?: string): OrderViewType {
        if (orderDetailViewCookie && orderDetailViewCookie === `compact`) {
            return `compact`;
        } else if (orderDetailViewCookie && orderDetailViewCookie === `standard`) {
            return `standard`;
        } else if (orderLines > COMPACTVIEWTHRESHOLD) {
            return `compact`;
        }
        return `standard`;
    }

    /**
     * Returns label to use for the checkout button
     */
    public getCheckoutButtonLabel(): string {
        if (this.ordrNeedsApprv) {
            return `Send For Approval`;
        } else {
            return `Place Order`;
        }
    }

    /**
     * Returns the appropriate URL to provide corresponding messaging
     */
    public getDeleteUrl(): string {
        return `/orders?${this.type === 'Q' ? 'deletedQuote' : 'deletedOrder'}=${this.ordrNbr}`;
    }

    /**
     * Returns the OrderLine matching provided itemID
     * @param itemID
     */
    public getOrderLineByItemId(itemID: string): OrderLine {
        if (itemID && this.allLines && this.allLines.length) {
            for (const orderLine of this.allLines) {
                if (orderLine.item && orderLine.item.toLowerCase() === itemID.toLowerCase()) {
                    return orderLine;
                }
            }
        }

        // orderLine not found
        return null;
    }

    /**
     * Provides example text for poHelp
     */
    public getPoExample() {
        if (this.poHelp && this.poHelp === `Must have exactly 8 characters`) {
            return `xxxxxxxx or xxxx-xxxx`;
        } else {
            return ``;
        }
    }

    /**
     * Translates a number string to a carrier for improved readability in your
     * order templates
     * @returns - Human readable shipVia
     */
    public getPrettyShipVia(): string {
        switch ((this.shipVia || ``).toLowerCase()) {
            case `1`:
                return `UPS Ground`;
            case `2`:
                return `Parcel Post`;
            case `3`:
                return `Common Carrier (Truck)`;
            case `4`:
                return `Pickup/Delivery`;
            case `5`:
                return `UPS Second Day`;
            case `9`:
                return `FedEx Ground (RPS)`;
            case `a`:
                return `UPS Next Day Air`;
            case `d`:
                return `UPS Third Day`;
            case `e`:
                return `Expeditors (Canada)`;
            case `f`:
                return `FedEx International`;
            case `i`:
                return `International`;
            case `w`:
                return `FedEx Standard Overnight`;
            case `x`:
                return `FedEx Priority Overnight`;
            case `y`:
                return `FedEx 2 Day`;
            case `z`:
                return `FedEx Express Saver(3 day)`;
            default:
                return `Unknown (${this.shipVia})`;
        }
    }

    /**
     * Returns true if the user has corporate allowance available for payment
     */
    public get hasCorpAllowance(): boolean {
        return !!this.hasAllowance && this.remainingBal > 0 && !!this.allowancePcode;
    }

    /**
     * Loops through allLines and each of their alerts and returns true if at least one item is marked as pandemicItem
     */
    public get hasPandemicItems(): boolean {
        let foundPandemic = false;
        this.allLines.forEach((item) => {
            if (foundPandemic) {
                return;
            }
            if (!item.alerts) {
                return;
            }
            item.alerts.forEach((alert) => {
                if (alert.pandemicItem) {
                    foundPandemic = true;
                }
            });
        });
        return foundPandemic;
    }

    /**
     * Tests if the order provided can be edited
     * @param order - Order to be tested
     * @returns - If the order can be edited
     */
    public static isEditable(order: Order | OrderHeader): boolean {
        if (!order) {
            return false;
        }
        return !order.orderReadOnly;
    }

    /**
     * Returns true if provided addressString is a PO Box
     * @param addressString - Address string to test
     */
    public static isPoBox(addressString: string): boolean {
        if (addressString) {
            const isPoBoxRegex = /^(((p[\s.]?[o\s][.]?)\s?)|(post\s?office\s?))((box|bin|b\.?)?\s?(num|number|#)?\s?\d+)/i;
            return isPoBoxRegex.test(addressString);
        }

        // Falsy values are not a PO Box
        return false;
    }

    /**
     * Tests validity of order number
     * @param ordrNbr - Order number to test
     */
    public static isValidOrderNumber(ordrNbr: string): boolean {
        const validOrderRegex = new RegExp(`^${validOrderRegexString}$`, `i`);
        return validOrderRegex.test(ordrNbr);
    }

    /**
     * Determines if supplied US or Canadian zip code is valid
     * @param zipCode - Zip code to evaluate
     */
    public static isValidUSAndCanadianZipCode(zipCode: string): boolean {
        const canadaAndUsZipCodes = /^(\d{5}(-\d{4})?|[A-CEGHJ-NPRSTVXY]\d[A-CEGHJ-NPRSTV-Z] ?\d[A-CEGHJ-NPRSTV-Z]\d)$/;
        return canadaAndUsZipCodes.test(zipCode);
    }

    /**
     * Determines if supplied US zip code is valid
     * @param zipCode - Zip code to evaluate
     */
    public static isValidUsZipCode(zipCode: string): boolean {
        const usZipCodes = /(^\d{5}$)|(^\d{5}-\d{4}$)/;
        return usZipCodes.test(zipCode);
    }

    /**
     * Returns true if order lines should be grouped by require approval
     */
    public groupByRequireApproval(): boolean {
        return !!this.cid && this.ordrNeedsApprv;
    }

    /**
     * Returns true if order approval amount meter should be displayed
     */
    public showOrderApprovalMeter(): boolean {
        return !!(this.amountSetup && this.approvalAmt);
    }

    /**
     * Returns true if shipping meter should be displayed
     */
    public showShippingMeter(user: User): boolean {
        return !!(user && user.canViewPrice() && this.shipMeter && this.frtPaidAmt);
    }

    /**
     * Determines if shippingSaverAdSavings should be shown
     * @param user
     */
    public showShippingSaverAdSavings(user: User) {
        const orderAmountAsNum = this.amount;
        return (
            orderAmountAsNum > 100 &&
            orderAmountAsNum < 399 &&
            user.allowShippingSaver() &&
            !user.hasActiveShippingSaver() &&
            this.aprxFrtChrg !== 0
        );
    }

    /**
     * Sorts order lines by B3G1 promotion priority
     * @param orderLines - OrderLine array to sort
     */
    public static sortOrderLines(orderLines: OrderLine[]): OrderLine[] {
        const sortedOrderLines = orderLines;

        // Sort orderLines
        sortedOrderLines.sort((orderLine1, orderLine2) => {
            // Show promo items first
            if (orderLine1.promo < orderLine2.promo) {
                return 1;
            }
            if (orderLine1.promo > orderLine2.promo) {
                return -1;
            }

            // Show items associated with promo next
            if (orderLine1.campid < orderLine2.campid) {
                return 1;
            }
            if (orderLine1.campid > orderLine2.campid) {
                return -1;
            }
            return 0;
        });

        // Return sortedOrderLines
        return sortedOrderLines;
    }

    /**
     * Tests if the supplied poNumber is valid for that order
     * @param poNumber - PO Number to evaluate
     * @returns Validation error message (or empty string if valid)
     */
    public validatePoNumber(poNumber: string): string {
        // If no poNumber and order requires it, return error
        if (!poNumber && this.mustProvidePO) {
            return `Must provide a PO number`;
        }

        // If the order has a specified regex, test that
        if (this.poRegex) {
            if (!safeRegex(this.poRegex)) {
                return `Invalid PO RegEx setup`;
            }
            const validPoRegex = new RegExp(`${this.poRegex}`, `i`);
            if (!validPoRegex.test(poNumber || ``)) {
                return `Invalid PO number - ${this.poHelp}`;
            }
        }

        // Else return no message
        return ``;
    }

    /**
     * Changes aprxFrtTot to num, to be consistent with other representations
     * @private
     */
    private _changeAprxFrtTotToNum() {
        if (this.aprxFrtTot && typeof this.aprxFrtTot === `string`) {
            this.aprxFrtTot = parseFloat(this.aprxFrtTot as any);
        }
    }
}
